const HOME = "/";
const REGISTER = "/register";
const SINGPASS_REGISTER = "/register/singpass";
const LOGIN = "/login";
const TWO_FA = "/2fa";
const VERIFICATION = "/verification";
const INDIVIDUAL_VERIFICATION = "/verification/individual";
const ORGANIZATION_VERIFICATION = "/verification/company";
const EMAIL_VERIFICATION = "/email-verification";
const EMAIL_VERIFIED = "/email-verified";
const VERIFY = "/verify";
const FORGOT_PASSWORD = "/forgot-password";
const KILDE_ACCREDITED_USER = "/Kilde-AU-thankyou";
const KILDE_REJECTED_USER = "/rejected-thankyou";
const CALLBACK = "/callback";
const TRANCH_LISTING = "/tranche-listing";
const BORROWER = "/borrower";
const SETTINGS = "/settings";
const CHANGE_PASSWORD = "/settings/change-password";
const ADDITIONAL_DOCUMENT = "/settings/additional-document";
const TWO_FACTOR_AUTH = "/settings/two-factor-auth";
const TWO_FA_SMS = "/settings/two-factor-auth/sms";
const TWO_FA_AUTHENTICATEAPP = "/settings/two-factor-auth/aunthenticateapp";
const DASHBOARD = "/dashboard";
const ACCOUNT_STATEMENT = "/account-statement";
const WALLET = "/wallet";
const TRANCH_INVEST = "/tranche-invest";
const CREATE_STRATEGY = "/autoinvestment/create-strategy";
const EDIT_STRATEGY = "/autoinvestment/edit-strategy";
const HELP_DESK = "/help-desk";
const VERIFF = "/test";


const ROUTES = {
  HOME,
  REGISTER,
  SINGPASS_REGISTER,
  LOGIN,
  TWO_FA,
  VERIFICATION,
  INDIVIDUAL_VERIFICATION,
  ORGANIZATION_VERIFICATION,
  EMAIL_VERIFICATION,
  FORGOT_PASSWORD,
  EMAIL_VERIFIED,
  VERIFY,
  KILDE_ACCREDITED_USER,
  KILDE_REJECTED_USER,
  CALLBACK,
  TRANCH_LISTING,
  BORROWER,
  SETTINGS,
  CHANGE_PASSWORD,
  ADDITIONAL_DOCUMENT,
  TWO_FACTOR_AUTH,
  TWO_FA_AUTHENTICATEAPP,
  TWO_FA_SMS,
  DASHBOARD,
  ACCOUNT_STATEMENT,
  WALLET,
  TRANCH_INVEST,
  CREATE_STRATEGY,
  EDIT_STRATEGY,
  VERIFF,
  HELP_DESK
};

export default ROUTES;
