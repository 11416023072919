import React from "react";
import GeneralInfo from "./GeneralInfo";
import {
  generalInformation,
  gettingStarted,
  verificationAndSecurity,
  investing,
  autoInvest,
  taxation,
} from "../../Utils/Reusables";

const RenderHelpDeskComponent = ({ tab }) => {
  const renderComponent = () => {
    switch (tab) {
      case 1:
        return (
          <GeneralInfo
            information={generalInformation}
            title="General Information"
          />
        );
      case 2:
        return (
          <GeneralInfo information={gettingStarted} title="Getting Started" />
        );
      case 3:
        return (
          <GeneralInfo
            information={verificationAndSecurity}
            title="Verification and Security"
          />
        );
      case 4:
        return <GeneralInfo information={investing} title="Investing" />;
      case 5:
        return <GeneralInfo information={autoInvest} title="Auto-investment" />;
      case 6:
        return <GeneralInfo information={taxation} title="Taxation" />;
      default:
        return (
          <div style={{ padding: "20px", textAlign: "center" }}>
            <h3>No Content Available</h3>
            <p>Please select a valid tab to view the content.</p>
          </div>
        );
    }
  };

  return <div>{renderComponent()}</div>;
};

export default RenderHelpDeskComponent;
