import React from "react";

import { Collapse } from "antd";

import plus_collapse from "../../Assets/Images/plus_collapse.svg";
import minus_collapse from "../../Assets/Images/minus_collapse.svg";

const { Panel } = Collapse;

const GeneralInfo = ({ information, title }) => {
  return (
    <div>
      <p className="kl-pi-subdivtitle">{title}</p>
      <Collapse
        className="help-desk-collapse"
        bordered={false}
        expandIcon={({ isActive }) =>
          isActive ? (
            <img src={minus_collapse} alt="plus_collapse" />
          ) : (
            <img src={plus_collapse} alt="plus_collapse" />
          )
        }
      >
        {information.map((item, index) => (
          <Panel header={item.header} key={index}>
            <p className="helpdesk-description">{item.description}</p>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default GeneralInfo;
