/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Spin,
  message,
  notification,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import ButtonDefault from "../../../Components/ButtonDefault/ButtonDefault";
import InputDefault from "../../../Components/InputDefault/InputDefault";
import {
  CommitInvest,
  Invest,
  InvestTranche,
  trancheAcceptanceDownload,
} from "../../../Apis/DashboardApi";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setTrancheResponse } from "../../../Redux/Action/Investor";
import Borrower_warning from "../../../Assets/Images/Icons/Dashboard/borrower_warning.svg";
import {
  formatCurrency,
  showMessageWithCloseIcon,
  showMessageWithCloseIconError,
} from "../../../Utils/Reusables";
import { trackEvent } from "../../../Utils/Analytics";
import { LoadingOutlined } from "@ant-design/icons";
import { britishFormatDate } from "../../../Utils/Helpers";
import TagSimple from "../../../Assets/Images/SVGs/TagSimple.svg";
import { getUser } from "../../../Apis/UserApi";
import { setUserDetails } from "../../../Redux/Action/User";

const StartInvesting = ({ TrancheRes, setLoader, couponValue }) => {
  const api = notification;

  const dispatch = useDispatch();
  const { slug } = useParams();
  const [investModal, setInvestModal] = useState(false);
  const [investLoader, setInvestLoader] = useState(false);
  const [investQuickLoader, setInvestQuickLoader] = useState(false);
  const [commiInvestLoader, setcommitInvestLoader] = useState(false);
  const [investAmount, setInvestAmount] = useState({
    amount: couponValue ? couponValue : "",
  });
  const [investmentVal, setInvestmentVal] = useState();
  const [checked, setChecked] = useState(false);
  const [noCheckErr, setCheckErr] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const user = useSelector((state) => state.user);
  const [validationErrors, setValidationErrors] = useState({
    amount: false,
  });
  const [investAmt, setInvestAmt] = useState("");

  const [downloadPdfLoader, setDownloadPdfLoader] = useState(false);
  const [apisCallTime, setApiCallTime] = useState();
  const [afterFewSecound, setAfterFewSecound] = useState(false);

  const openNotificationWithIcon = (type) => {
    api[type]({
      description:
        "We're processing your investment, which may take a little longer than usual due to its substantial size. Thank you for your patience.",
      key: "uniqueNotificationKey",
      duration: null,
    });
  };

  useEffect(() => {
    if (afterFewSecound) {
      openNotificationWithIcon("info");
    } else {
      api.destroy("uniqueNotificationKey");
    }
  }, [afterFewSecound]);

  useEffect(() => {
    if (apisCallTime > 5000) {
      setAfterFewSecound(true);
    } else {
      setAfterFewSecound(false);
    }
  }, [apisCallTime]);

  useEffect(() => {
    if (investAmount?.amount && investModal === true) {
      setInvestAmt(investAmount.amount);
    }
  }, [investAmount, investModal]);

  useEffect(() => {
    const fetchBalance = async () => {
      if (user?.accounts !== undefined) {
        const accBalance = await handleCheckBalance(user?.accounts);
        setWalletBalance(accBalance);
      }
    };

    fetchBalance();
  }, [user?.accounts]);

  const handleCheckBalance = async (accounts) => {
    for (const currency of accounts) {
      if (currency?.currencyCode === TrancheRes?.tranche?.currencyCode) {
        return currency?.balance;
      }
    }
    return null;
  };

  const handleChange = (e) => {
    setValidationErrors({
      amount: false,
    });
    setInvestAmount({ ...investAmount, [e.target.name]: e.target.value });
  };

  const handleInvest = () => {
    setInvestLoader(true);
    if (
      user?.secondFactorAuth === null &&
      user?.twoFaCheckEnabled === true &&
      user?.investorStatus === "ACTIVE"
    ) {
      showMessageWithCloseIconError(
        "Enable Two Factor Authentication to start investing"
      );
      setInvestLoader(false);
    } else if (investAmount?.amount === "") {
      setValidationErrors({
        amount: true,
      });
      showMessageWithCloseIconError("Please enter an amount.");
      setInvestLoader(false);
    } else if (
      investAmount?.amount < TrancheRes?.tranche?.nominalValue &&
      investAmount?.amount !== ""
    ) {
      setValidationErrors({
        amount: true,
      });
      showMessageWithCloseIconError(
        "Please enter amount more than nominal value"
      );
      setInvestLoader(false);
    } else {
      setValidationErrors({
        amount: false,
      });
      finalInvest(investAmount?.amount);
    }
  };
  const handleQuickInvest = () => {
    setInvestQuickLoader(true);
    if (investAmt === "") {
      setValidationErrors({
        amount: true,
      });
      message.error("Please enter an amount.");
      setInvestLoader(false);
    } else if (
      investAmt < TrancheRes?.tranche?.nominalValue &&
      investAmt !== ""
    ) {
      setValidationErrors({
        amount: true,
      });
      message.error("Please enter amount more than nominal value");
      setInvestLoader(false);
    } else {
      setValidationErrors({
        amount: false,
      });
      finalInvest(investAmt);
    }
  };

  const finalInvest = (amount) => {
    const requestBody = {
      trancheUuid: TrancheRes?.tranche?.uuid,
      amount: amount,
      acceptTerms: false,
      acceptLetterOfWaiver: false,
    };

    const startTime = performance.now();
    let elapsedTime = 0;

    const intervalId = setInterval(() => {
      elapsedTime = performance.now() - startTime;
      setApiCallTime(elapsedTime);
    }, 1);

    Invest(requestBody)
      .then(async (investmentData) => {
        clearInterval(intervalId);

        const endTime = performance.now();
        const apiCallTime = endTime - startTime;
        setApiCallTime(apiCallTime.toFixed(2));

        if (Object.keys(investmentData)?.length > 0) {
          if (investmentData?.offer?.hasErrors === true) {
            setInvestmentVal(investmentData?.offer);
            setValidationErrors({ amount: true });
            showMessageWithCloseIconError(
              "There was a problem generating offer."
            );
            setInvestLoader(false);
          } else {
            setInvestmentVal(investmentData?.offer);
            setInvestLoader(false);
            setInvestQuickLoader(false);
            setInvestModal(true);
            setAfterFewSecound(false);
            if (investmentData?.offer?.totalCostForInvestor !== 0) {
              setValidationErrors({ amount: false });
            }
            setApiCallTime(0);
          }
        } else {
          setAfterFewSecound(false);
          setApiCallTime(0);
          setInvestLoader(false);
          setInvestQuickLoader(false);
        }
      })
      .catch((error) => {
        clearInterval(intervalId);

        const endTime = performance.now();
        const apiCallTime = endTime - startTime;
        setApiCallTime(apiCallTime);
        ErrorResponse(error?.code);
        setInvestLoader(false);
        setInvestQuickLoader(false);
      });
  };

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleCommitInvestment = () => {
    setcommitInvestLoader(true);
    if (checked === false) {
      setcommitInvestLoader(false);
      return setCheckErr(true);
    } else {
      const requestBody = {
        trancheUuid: TrancheRes?.tranche?.uuid,
        amount: investAmount?.amount,
      };

      const startTime = performance.now();
      let elapsedTime = 0;

      const intervalId = setInterval(() => {
        elapsedTime = performance.now() - startTime;
        setApiCallTime(elapsedTime);
      }, 1);

      CommitInvest(requestBody)
        .then(async (commitInvestmentData) => {
          clearInterval(intervalId);

          const endTime = performance.now();
          const apiCallTime = endTime - startTime;
          setApiCallTime(apiCallTime.toFixed(2));

          if (Object.keys(commitInvestmentData)?.length > 0) {
            setApiCallTime(0);
            api.destroy("uniqueNotificationKey");
            setAfterFewSecound(false);
            trackEvent("investing", "createSubmitSuccess", {
              user_id: user?.number,
            });
            window?.dataLayer?.push({
              event: "createSubmitSuccess",
              user_id: user?.number,
            });
            setInvestmentVal("");
            setcommitInvestLoader(false);
            showMessageWithCloseIcon(
              "Congratulations! Your subscription is successful."
            );
            getUserDetails();
            setInvestModal(false);
            handleGetTranche();
          } else {
            setApiCallTime(0);
            setAfterFewSecound(false);
            setcommitInvestLoader(false);
          }
        })
        .catch((error) => {
          setApiCallTime(0);
          setAfterFewSecound(false);
          ErrorResponse(error?.code);
          setcommitInvestLoader(false);
        });
    }
  };

  const handleGetTranche = () => {
    setLoader(true);
    const requestBody = {
      trancheUuid: slug,
    };
    InvestTranche(requestBody).then(async (tracheRes) => {
      await setTrancheResponse(tracheRes, dispatch);
      setLoader(false);
    });
  };

  const handleDownLoadTrancheAcceptancePdf = () => {
    const data = {
      trancheId: slug,
      amount: investAmount?.amount,
    };
    try {
      setDownloadPdfLoader(true);

      trancheAcceptanceDownload(data)
        .then(async (response) => {
          if (!response.data || response.data.size === 0) {
            throw new Error("Empty file received from server");
          }

          const contentType = response.headers["content-type"];
          const blob = new Blob([response.data], { type: contentType });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = response.headers["k-filename"] || "download.pdf";
          link.click();
          setDownloadPdfLoader(false);
        })
        .catch((error) => {
          console.error("Error fetching account summary:", error);
          setDownloadPdfLoader(false);
        });
    } catch (e) {
      console.error("Error fetching account summary:", e);
      setDownloadPdfLoader(false);
    }
  };

  const handleInvestmentChange = (value) => {
    setInvestQuickLoader(true);
    setInvestAmt(value);
    setInvestAmount({ ...investAmount, amount: value });
  };

  const fetchInvestmentData = useCallback(async () => {
    if (investAmt === "") {
      setValidationErrors({ amount: true });
      message.error("Please enter an amount.");
      setInvestQuickLoader(false);
    } else if (
      investAmt < TrancheRes?.tranche?.nominalValue &&
      investAmt !== ""
    ) {
      setValidationErrors({ amount: true });
      message.error("Please enter amount more than nominal value.");
      setInvestQuickLoader(false);
    } else {
      handleQuickInvest();
    }

    setInvestQuickLoader(false);
  }, [investAmt]);

  useEffect(() => {
    if (investAmt !== "") {
      const delayDebounceFn = setTimeout(() => {
        fetchInvestmentData();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setInvestQuickLoader(false);
    }
  }, [investAmt]);

  const isFutureDate = (dateString) => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    return givenDate > currentDate;
  };

  return (
    <Col
      className="gutter-row add-info-div p-relative"
      lg={24}
      md={24}
      sm={24}
      xs={24}
    >
      <p className="add-info-head m-0">
        {TrancheRes?.tranche?.subscriptionEnabled === true
          ? "Start investing"
          : isFutureDate(TrancheRes?.tranche?.issueDate)
          ? `Investment will begin on ${britishFormatDate(
              TrancheRes?.tranche?.issueDate
            )}.`
          : "Fully funded"}
      </p>
      <Form
        onFinish={handleInvest}
        name="wrap"
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <div
          className="mt-15 start-invest-div"
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: window.innerWidth <= 576 ? "column" : "row",
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{ flex: 1, width: "100%" }}
            className={validationErrors.amount === true ? "error-border" : ""}
          >
            <div style={{ position: "relative" }}>
              <InputDefault
                value={investAmount?.amount}
                placeholder="Enter amount to invest"
                type="number"
                name="amount"
                onChange={handleChange}
                required={true}
                errorMsg={"Amount is Required"}
                style={{ backgroundColor: "#ffffff" }}
                disabled={
                  user?.investorStatus !== "ACTIVE" ||
                  isFutureDate(TrancheRes?.tranche?.issueDate) ||
                  (user?.secondFactorAuth === null &&
                    user?.twoFaCheckEnabled === true)
                }
              />
              {validationErrors.amount === true && (
                <div className="borrower-warning-logo">
                  <img src={Borrower_warning} alt="Borrower_warning" />
                </div>
              )}
            </div>
            {validationErrors.amount === true &&
            investAmount.amount !== "" &&
            investAmount?.amount < TrancheRes?.tranche?.nominalValue ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount more than the nominal value
              </span>
            ) : investmentVal?.hasErrors === true ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                There was a problem generating offer.
              </span>
            ) : investAmount?.amount === "" &&
              validationErrors.amount === true ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount.
              </span>
            ) : null}
            {TrancheRes?.tranche?.subscriptionEnabled === true ? (
              <p className="add-info-note mt-4 mb-0">
                Min. amount{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.nominalValue
                )}
                , max{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.principalAvailable
                )}
              </p>
            ) : null}
          </div>
          <div style={{ width: window.innerWidth <= 576 ? "100%" : null }}>
            <Button
              className="invest-btn"
              loading={investLoader}
              style={{ width: "100%" }}
              disabled={
                user?.investorStatus !== "ACTIVE" ||
                isFutureDate(TrancheRes?.tranche?.issueDate)
              }
              onClick={handleInvest}
            >
              Invest
            </Button>
          </div>
        </div>
      </Form>

      <Modal
        centered
        open={investModal}
        onCancel={() => {
          setInvestModal(false);
          setCheckErr(false);
        }}
        width={460}
        footer={null}
        maskClosable={false}
        className="invest-modal"
      >
        <p className="m-0 tranch-head mb-24">Start investing</p>

        <div
          className="start-invest-div"
          style={{ display: "flex", alignItems: "start" }}
        >
          <div
            style={{ flex: 1 }}
            className={
              validationErrors.amount === true ? "error-border-modal" : ""
            }
          >
            <div style={{ position: "relative" }}>
              <InputDefault
                placeholder="Enter amount to invest"
                type="text"
                name="invamt"
                onChange={(e) => handleInvestmentChange(e.target.value)}
                required={true}
                errorMsg={"Amount is Required"}
                style={{ backgroundColor: "#ffffff" }}
                disabled={
                  TrancheRes?.tranche?.subscriptionEnabled === false ||
                  user?.investorStatus !== "ACTIVE"
                }
                value={investAmt}
                prefix={
                  <span>
                    {TrancheRes?.tranche?.currencyCode === "USD"
                      ? "$"
                      : TrancheRes?.tranche?.currencyCode === "SGD"
                      ? "S$"
                      : "€"}
                  </span>
                }
              />
              {validationErrors.amount === true && (
                <div className="borrower-warning-logo">
                  <img src={Borrower_warning} alt="Borrower_warning" />
                </div>
              )}
            </div>
            {validationErrors.amount === true &&
            investAmt !== "" &&
            investAmt < TrancheRes?.tranche?.nominalValue ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount more than the nominal value
              </span>
            ) : investmentVal?.hasErrors === true ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                There was a problem generating offer.
              </span>
            ) : investAmt > walletBalance && walletBalance === 0 ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please deposit {formatCurrency("", investAmt - walletBalance)}{" "}
                to your {TrancheRes?.tranche?.currencyCode} wallet.
              </span>
            ) : investAmt === "" && validationErrors.amount === true ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                Please enter an amount.
              </span>
            ) : null}
            {TrancheRes?.tranche?.subscriptionEnabled === true ? (
              <p className="add-info-note-model mt-4 mb-0">
                Min. amount{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.nominalValue
                )}
                , max{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  TrancheRes?.tranche?.principalAvailable
                )}
              </p>
            ) : null}
            {/* {investAmt > walletBalance ? (
              <p className="mt-5 mb-0 notes-for-invest">
                Your wallet balance is only enough to invest a maximum of{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.principal
                )}
                .
              </p>
            ) : null} */}
            {investAmt > TrancheRes?.tranche?.principalAvailable ? (
              <p className="mt-5 mb-0 notes-for-invest">
                The maximum investment for this deal is{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.principal
                )}
                .
              </p>
            ) : investAmt > walletBalance && walletBalance !== 0 ? (
              <p className="mt-5 mb-0 notes-for-invest">
                Your wallet balance is only enough to invest a maximum of{" "}
                {formatCurrency(
                  TrancheRes?.tranche?.currencyCode === "USD"
                    ? "$"
                    : TrancheRes?.tranche?.currencyCode === "SGD"
                    ? "S$"
                    : "€",
                  investmentVal?.principal
                )}
                .
              </p>
            ) : null}
          </div>
        </div>
        <div>
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 50, color: "var(--kilde-blue)" }}
              />
            }
            spinning={investQuickLoader}
          >
            <div className="mt-24">
              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0">Principal</p>
                </div>
                <div>
                  <p className="m-0">
                    {formatCurrency(
                      TrancheRes?.tranche?.currencyCode === "USD"
                        ? "$"
                        : TrancheRes?.tranche?.currencyCode === "SGD"
                        ? "S$"
                        : "€",
                      investmentVal?.principal
                    )}
                  </p>
                </div>
              </div>
              {TrancheRes?.tranche?.discountedPrice ? (
                <div className="sb-flex-justify-between mb-16">
                  <div className="discount-tag">
                    <img src={TagSimple} alt="TagSimple" />
                    <p className="m-0 mb-0">Discounted price</p>
                  </div>
                  <div>
                    <p className="m-0">
                      {formatCurrency(
                        TrancheRes?.tranche?.currencyCode === "USD"
                          ? "$"
                          : TrancheRes?.tranche?.currencyCode === "SGD"
                          ? "S$"
                          : "€",
                        investmentVal?.discountedPrice
                      )}
                    </p>
                  </div>
                </div>
              ) : null}

              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0">Accrued interest</p>
                </div>
                <div>
                  <p className="m-0">
                    {formatCurrency(
                      TrancheRes?.tranche?.currencyCode === "USD"
                        ? "$"
                        : TrancheRes?.tranche?.currencyCode === "SGD"
                        ? "S$"
                        : "€",
                      investmentVal?.accruedInterest === null
                        ? 0
                        : investmentVal?.accruedInterest
                    )}
                  </p>
                </div>
              </div>
              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0">Subscription fee</p>
                </div>
                <div>
                  <p className="m-0">
                    {formatCurrency(
                      TrancheRes?.tranche?.currencyCode === "USD"
                        ? "$"
                        : TrancheRes?.tranche?.currencyCode === "SGD"
                        ? "S$"
                        : "€",
                      investmentVal?.investorSubscriptionFee === null
                        ? 0
                        : investmentVal?.investorSubscriptionFee
                    )}
                  </p>
                </div>
              </div>
              <div className="sb-flex-justify-between mb-16">
                <div>
                  <p className="m-0 fw-600">Total funds to commit</p>
                </div>
                <div>
                  <p className="m-0 fw-600">
                    {formatCurrency(
                      TrancheRes?.tranche?.currencyCode === "USD"
                        ? "$"
                        : TrancheRes?.tranche?.currencyCode === "SGD"
                        ? "S$"
                        : "€",
                      investmentVal?.totalCostForInvestor === null
                        ? 0
                        : investmentVal?.totalCostForInvestor
                    )}
                  </p>
                </div>
              </div>
              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckErr(false);
                  } else {
                    setCheckErr(true);
                  }
                  setChecked(e.target.checked);
                }}
                key="borrower"
                className="checkbox-kilde"
              >
                I accept{" "}
                <Button
                  loading={downloadPdfLoader}
                  onClick={handleDownLoadTrancheAcceptancePdf}
                  className="acceptance-btn"
                  style={{ color: "var(--kilde-blue)", fontSize: "14px" }}
                >
                  investment terms
                </Button>
              </Checkbox>
              {noCheckErr && (
                <label
                  className="error-msg mt-0"
                  style={{
                    display: "block",
                    marginBottom: 12,
                    fontSize: "12px",
                  }}
                >
                  Please accept the Investment terms
                </label>
              )}
            </div>

            <div className="sb-text-align mt-24">
              <ButtonDefault
                style={{ width: "100%" }}
                className="commit-btn"
                title="Commit"
                onClick={handleCommitInvestment}
                loading={commiInvestLoader}
                disabled={
                  investmentVal?.principal <= 0
                  // || investmentVal?.hasErrors === true
                }
              />
            </div>
          </Spin>
        </div>
      </Modal>
    </Col>
  );
};

export default StartInvesting;
